<template>
  <vx-card class="px-6 py-3">
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-3">
        <vs-input
          class="w-full"
          placeholder="Nombre de la capacitación"
          label="Nombre"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-3">
        <vs-input placeholder="Puntos" class="w-full" label="Puntos" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mt-3">
        <vs-textarea
          class="w-full"
          label="Descripción"
          placeholder="Descripción de la capacitación"
        />
      </div>
    </div>
    <div class="vx-row items-end">
      <div class="vx-col sm:w-1/4 w-full mb-3">
        <vs-input class="w-full" label="Link video" placeholder="Link video" />
      </div>
      <div class="vx-col sm:w-1/4 w-full mb-3">
        <vs-input
          class="w-full"
          label="Link encuesta"
          placeholder="Link encuesta"
        />
      </div>
      <div class="vx-col sm:w-1/4 w-full mb-3">
        <vs-input
          class="w-full"
          label="Secuencia"
          placeholder="Nº secuencia del video"
        />
      </div>
      <div class="vx-col w-full sm:w-1/4 mb-3">
        <vs-checkbox class="inline-flex text-slate-gray"
          >Obligatorio</vs-checkbox
        >
        <vs-checkbox class="inline-flex text-slate-gray">Registro</vs-checkbox>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2">Guardar</vs-button>
        <vs-button type="border" class="mb-3">Descartar</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {};
</script>

<style></style>
